import React from "react";
import { IconProps } from "../icon";

export const Camera_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 7.702A2.702 2.702 0 014.702 5c.775 0 1.463-.496 1.709-1.232L6.5 3.5c.042-.127.063-.19.086-.246a2 2 0 011.735-1.25C8.38 2 8.448 2 8.58 2h6.838c.133 0 .2 0 .26.004a2 2 0 011.735 1.25c.023.056.044.12.086.246l.09.268A1.801 1.801 0 0019.297 5 2.702 2.702 0 0122 7.702V16.2c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C19.72 21 18.88 21 17.2 21H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C2 18.72 2 17.88 2 16.2V7.702z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 16.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9z"
    />
  </svg>
);
