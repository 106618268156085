import React from "react";
import { IconProps } from "../icon";

export const Image_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 20l8.869-8.869c.396-.396.594-.594.822-.668a1 1 0 01.618 0c.228.074.426.272.822.668l4.274 4.274M10.5 8.5a2 2 0 11-4 0 2 2 0 014 0zM22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"
    />
  </svg>
);
